const states = animationStates();
const timelineStates = timelineStates();

let currentStateIndex = 0;

const DIRECTIONS = {
  UP: "up",
  DOWN: "down"
};

function initFullpage() {
  initAudio();

  let timeLine = new TimelineMax({
    repeat: 0,
    repeatDelay: 0,
    yoyo: false
  });

  const TABLET_BREAKPOINT_VALUE = 1024;

  let allowScroll = true;
  var clickMove = false;

  let updateDesktopAnimation;
  let updateMobileAnimation;

  let currentIndex;
  let currentDirection;
  const video = document.querySelector("#guitar-video");

  function createTimeline(container, appear = false, nextTimeline) {
    const TRANSITION = 0.5;
    const OFFSET = -10;

    return new TimelineMax({ paused: true })
      .fromTo(
        container + " .animation-block1",
        TRANSITION,
        {
          opacity: appear ? 0 : 1,
          y: appear ? OFFSET : 0
        },
        {
          opacity: appear ? 1 : 0,
          y: appear ? 0 : OFFSET,
          onStart: function() {
            allowScroll = false;
            fullpage_api.setAllowScrolling(false);
            fullpage_api.setKeyboardScrolling(false);
          }
        }
      )
      .fromTo(
        container + " .animation-block2",
        TRANSITION,
        {
          opacity: appear ? 0 : 1,
          y: appear ? OFFSET : 0
        },
        {
          opacity: appear ? 1 : 0,
          y: appear ? 0 : OFFSET
        },
        0.1
      )
      .fromTo(
        container + " .animation-block3",
        TRANSITION,
        {
          opacity: appear ? 0 : 1,
          y: appear ? OFFSET : 0
        },
        {
          opacity: appear ? 1 : 0,
          y: appear ? 0 : OFFSET,
          onComplete: playNextTimeline.bind(this, nextTimeline)
        },
        0.2
      );
  }

  function playNextTimeline(timeLine) {
    allowScroll = true;
    activateScroll(true);
    if (timeLine) {
      timeLine.play();
    }
  }

  function createFadeGuitarShadowTimeline(
    direction,
    originIndex,
    destinationIndex
  ) {
    return new TimelineMax().fromTo(
      ".guitar-shadow",
      "1",
      {
        opacity: direction === DIRECTIONS.DOWN ? "1" : "0"
      },
      {
        opacity: direction === DIRECTIONS.DOWN ? "0" : "1",
        onStart: function() {
          direction === DIRECTIONS.UP && destinationIndex === 0
            ? $(".guitar-shadow").removeClass("hidden")
            : null;
        },
        onComplete: function() {
          direction === DIRECTIONS.DOWN
            ? $(".guitar-shadow").addClass("hidden")
            : null;
          direction === DIRECTIONS.UP
            ? TweenMax.set($(".guitar-shadow"), { clearProps: "all" })
            : null;
        }
      },
      direction === DIRECTIONS.DOWN ? 0 : states[originIndex].shadowDelay
    );
  }

  function createSoloSoundTimelne(direction, appear, destinationIndex) {
    return new TimelineMax().fromTo(
      ".solo-sound",
      "0.5",
      {
        opacity: states[destinationIndex].soloAudioVisible ? "0" : "1",
        scale: states[destinationIndex].soloAudioVisible ? "0.5" : "1"
      },
      {
        opacity: states[destinationIndex].soloAudioVisible ? "1" : "0",
        scale: states[destinationIndex].soloAudioVisible ? "1" : "0.5",
        onStart: function() {
          states[destinationIndex].soloAudioVisible
            ? $(".overview-audio-container").addClass("animate")
            : null;
        },
        onComplete: function() {
          if (states[destinationIndex].soloAudioVisible === false) {
            $(".overview-audio-container").removeClass("animate");
          }
        }
      },
      direction === DIRECTIONS.DOWN ? 0 : 1
    );
  }

  function createSoundsTimeline(
    direction,
    appear,
    destinationIndex,
    originIndex
  ) {
    return new TimelineMax()
      .fromTo(
        ".am-sound",
        "0.5",
        {
          opacity: states[destinationIndex].audioVisible ? "0" : "1",
          scale: states[destinationIndex].audioVisible ? "0.5" : "1"
        },
        {
          opacity: states[destinationIndex].audioVisible ? "1" : "0",
          scale: states[destinationIndex].audioVisible ? "1" : "0.5",

          onStart: function() {
            states[destinationIndex].audioVisible
              ? $(".details-audio-container").addClass("animate")
              : null;
            allowScroll = false;
            activateScroll(false);
          }
        },
        direction === DIRECTIONS.DOWN
          ? states[destinationIndex].audioVisible
            ? 0.8
            : 0
          : states[originIndex].audioDelay
      )
      .fromTo(
        ".g-sound",
        "0.5",
        {
          opacity: states[destinationIndex].audioVisible ? "0" : "1",
          scale: states[destinationIndex].audioVisible ? "0.5" : "1"
        },
        {
          opacity: states[destinationIndex].audioVisible ? "1" : "0",
          scale: states[destinationIndex].audioVisible ? "1" : "0.5"
        },
        direction === DIRECTIONS.DOWN
          ? states[destinationIndex].audioVisible
            ? 0.9
            : 0
          : states[originIndex].audioDelay
      )
      .fromTo(
        ".f-sound",
        "0.5",
        {
          opacity: states[destinationIndex].audioVisible ? "0" : "1",
          scale: states[destinationIndex].audioVisible ? "0.5" : "1"
        },
        {
          opacity: states[destinationIndex].audioVisible ? "1" : "0",
          scale: states[destinationIndex].audioVisible ? "1" : "0.5"
        },
        direction === DIRECTIONS.DOWN
          ? states[destinationIndex].audioVisible
            ? 1
            : 0
          : states[originIndex].audioDelay
      )
      .fromTo(
        ".c-sound",
        "0.5",
        {
          opacity: states[destinationIndex].audioVisible ? "0" : "1",
          scale: states[destinationIndex].audioVisible ? "0.5" : "1"
        },
        {
          opacity: states[destinationIndex].audioVisible ? "1" : "0",
          scale: states[destinationIndex].audioVisible ? "1" : "0.5",

          onComplete: function() {
            allowScroll = true;
            activateScroll(true);
            if (states[destinationIndex].audioVisible === false) {
              $(".details-audio-container").removeClass("animate");
            }
          }
        },
        direction === DIRECTIONS.DOWN
          ? states[destinationIndex].audioVisible
            ? 1.1
            : 0
          : states[originIndex].audioDelay
      );
  }

  function activateScroll(boolean) {
    fullpage_api.setAllowScrolling(boolean);
    fullpage_api.setKeyboardScrolling(boolean);
  }

  function createFadeVideoTimeline(appear, destinationIndex, origin, fadeDown) {
    return new TimelineMax().fromTo(
      ".guitar-video",
      1,
      {
        y: fadeDown ? (appear ? "100%" : "0%") : "0%",
        opacity: fadeDown
          ? origin.index === 3
            ? "1"
            : "0"
          : origin.index >= states.length - 3
          ? "0"
          : "1"
      },
      {
        y: fadeDown ? (appear ? "0%" : "100%") : "0%",
        opacity: fadeDown
          ? origin.index === 4
            ? "1"
            : "0"
          : origin.index >= states.length - 3
          ? "1"
          : "0",
        ease: Sine.easeInOut,

        onStart: function() {
          $(".guitar-video").removeClass("hidden");
          origin.index >= states.length - 3
            ? (video.currentTime = states[currentStateIndex].videoTime)
            : null;
        },

        onComplete: function() {
          if (states[destinationIndex].videoTime >= 0) {
            video.currentTime = states[destinationIndex].videoTime;
          }
          if (
            destinationIndex < states.length - 3 &&
            origin.index < states.length - 3
          ) {
            new TimelineMax().fromTo(
              ".guitar-video",
              1,
              {
                opacity: "0"
              },
              {
                opacity: "1",
                ease: Sine.easeInOut,

                onStart: function() {
                  $(".guitar-video").removeClass("hidden");
                }
              }
            );
          }
        }
      },
      origin.index >= states.length - 3 ? 0.5 : 0
    );
  }

  const debounced = _.debounce(
    function(prevContainer, nextContainer) {
      $(prevContainer).removeClass("animate");
      $(nextContainer).addClass("animate");
    },
    200,
    { maxWait: 400 }
  );

  if ($(window).width() > TABLET_BREAKPOINT_VALUE) {
    updateDesktopAnimation = false;
    updateMobileAnimation = true;
  } else {
    updateDesktopAnimation = true;
    updateMobileAnimation = false;
  }

  function updateAnimationState() {
    const activeSection = fullpage_api.getActiveSection();

    if (updateDesktopAnimation && $(window).width() > TABLET_BREAKPOINT_VALUE) {
      currentStateIndex = activeSection.index;
      if (states[activeSection.index].videoTime >= 0) {
        video.currentTime = states[activeSection.index].videoTime;
        TweenMax.set($(".guitar-video"), { clearProps: "all" });
        setTimeout(() => {
          $(".guitar-video").removeClass("hidden");
        }, 100);
      }

      if (states[activeSection.index].soloAudioVisible) {
        $(".overview-audio-container").addClass("animate");
        createSoloSoundTimelne("down", false, activeSection.index);
      } else {
        $(".overview-audio-container").removeClass("animate");
      }

      if (states[activeSection.index].audioVisible) {
        $(".details-audio-container").addClass("animate");
        TweenMax.set($(".details-audio-container .audio-sound"), {
          clearProps: "all"
        });
        createSoundsTimeline(
          "down",
          false,
          activeSection.index,
          activeSection.index
        );
      } else {
        $(".details-audio-container").removeClass("animate");
      }
      updateDesktopAnimation = false;
    }

    if (updateMobileAnimation && $(window).width() <= TABLET_BREAKPOINT_VALUE) {
      $(".sequence-step.animate").removeClass("animate");
      $(".section-audio-container").removeClass("animate");
      TweenMax.set($(".sequence-step"), { clearProps: "all" });
      setTimeout(() => {
        $(".sequence-step-" + (activeSection.index + 1)).addClass("animate");
      }, 100);
      updateMobileAnimation = false;
    }
  }

  function animateMoveDown(container, direction) {
    return new TimelineMax().fromTo(
      container,
      1,
      {
        y: direction === DIRECTIONS.DOWN ? "0%" : "100%",
        opacity: direction === DIRECTIONS.DOWN ? "1" : "0"
      },
      {
        y: direction === DIRECTIONS.DOWN ? "100%" : "0%",
        opacity: direction === DIRECTIONS.DOWN ? "0" : "1",
        ease: Sine.easeInOut,
        onStart: function() {
          direction === DIRECTIONS.UP
            ? $(".sequence-step-4").addClass("animate")
            : null;
        }
      }
    );
  }

  function playSequence(direction, destinationIndex, originIndex) {
    if (
      (direction === DIRECTIONS.DOWN && destinationIndex < 4) ||
      (direction === DIRECTIONS.UP && destinationIndex < 3)
    ) {
      return new TimelineMax().fromTo(
        direction === DIRECTIONS.DOWN
          ? states[originIndex].container
          : states[destinationIndex].container,
        direction === DIRECTIONS.DOWN
          ? states[originIndex].animationDuration
          : states[destinationIndex].animationDuration,
        {
          backgroundPosition:
            direction === DIRECTIONS.DOWN ? "0% 0%" : "100% 0%"
        },
        {
          backgroundPosition:
            direction === DIRECTIONS.DOWN ? "100% 0%" : "0% 0%",
          ease:
            direction === DIRECTIONS.DOWN
              ? SteppedEase.config(states[originIndex].animationSteps)
              : SteppedEase.config(states[destinationIndex].animationSteps),

          onStart: function() {
            allowScroll = false;
            activateScroll(false);

            if (direction === DIRECTIONS.UP) {
              $(states[originIndex].container).removeClass("animate");
              $(states[destinationIndex].container).addClass("animate");
            }
          },

          onComplete: function() {
            if (direction === DIRECTIONS.DOWN) {
              if (destinationIndex !== 4) {
                debounced(
                  states[originIndex].container,
                  states[destinationIndex].container
                );
              }
              allowScroll = true;
              activateScroll(true);
            } else {
              if (destinationIndex !== 0) {
                debounced(
                  states[originIndex].container,
                  states[destinationIndex].container
                );
              }
              TweenMax.set($(states[originIndex].container), {
                clearProps: "all"
              });
            }
          }
        }
      );
    } else if (
      (direction === DIRECTIONS.DOWN && destinationIndex === 4) ||
      (direction === DIRECTIONS.UP && destinationIndex === 3)
    ) {
      animateMoveDown(".sequence-step-4", direction);
    }
  }

  //NAVIGATION

  $(".navigation-item").on("click", function(e) {
    clickMove = true;
    allowScroll = true;
  });
  
  $(".learn-more-button").on("click", function(){
    allowScroll = true;
    fullpage_api.moveSectionDown();
  });

  function updateState(direction, destinationIndex, clickMove, origin) {
    if (direction === DIRECTIONS.DOWN) {
      if (clickMove === false) {
        playAnimation(
          timeLine,
          states[currentStateIndex],
          direction,
          currentStateIndex,
          origin,
          destinationIndex
        );
        currentStateIndex = destinationIndex;
      } else {
        allowScroll = true;

        if (destinationIndex > currentStateIndex + 1) {
          video.pause();
          if (origin.index < 4) {
            createFadeVideoTimeline(false, destinationIndex, origin, false);
          }
          currentStateIndex = destinationIndex;
        } else if (destinationIndex === currentStateIndex + 1) {
          playAnimation(
            timeLine,
            states[currentStateIndex],
            direction,
            currentStateIndex,
            origin,
            destinationIndex
          );
          currentStateIndex = destinationIndex;
        }
      }
    } else if (direction === DIRECTIONS.UP) {
      if (clickMove === false) {
        currentStateIndex = destinationIndex;
        playAnimation(
          timeLine,
          states[currentStateIndex],
          direction,
          currentStateIndex,
          origin,
          destinationIndex
        );
      } else {
        allowScroll = true;

        if (destinationIndex < currentStateIndex - 1) {
          video.pause();
          if (destinationIndex < 4) {
            createFadeVideoTimeline(true, destinationIndex, origin, false);
          }
          currentStateIndex = destinationIndex;
        } else if (destinationIndex === currentStateIndex - 1) {
          currentStateIndex = destinationIndex;
          playAnimation(
            timeLine,
            states[currentStateIndex],
            direction,
            currentStateIndex,
            origin,
            destinationIndex
          );
        }

        if (currentStateIndex < states.length - 3) {
          $(".guitar-video").removeClass("hidden");
        }
      }
    }
    createSoloSoundTimelne(direction, false, destinationIndex);
    createSoundsTimeline(direction, false, destinationIndex, origin.index);
    createFadeGuitarShadowTimeline(direction, origin.index, destinationIndex);
  }

  function playAnimation(
    timeline,
    tweenConfig,
    direction,
    currentStateIndex,
    origin,
    destinationIndex
  ) {
    currentIndex = currentStateIndex;
    currentDirection = direction;
    activateScroll(false);

    if (direction === DIRECTIONS.DOWN) {
      if (intervalRewind) {
        intervalRewind = null;
      }
      clearInterval(intervalRewind);

      if (
        states[currentIndex + 1] &&
        (states[currentIndex + 1].videoTime ||
          states[currentIndex + 1].videoTime >= 0)
      ) {
        currentIndex = origin.index;
        video.play();
        video.addEventListener("timeupdate", function() {
          if (currentDirection === DIRECTIONS.DOWN) {
            if (
              states[currentIndex + 1] &&
              states[currentIndex + 1].videoTime &&
              video.currentTime > states[currentIndex + 1].videoTime
            ) {
              video.pause();
              activateScroll(true);
            }
          } else if (currentDirection === DIRECTIONS.UP) {
            return;
          }
        });
      } else {
        video.pause();
      }
    } else {
      if (states[currentIndex + 1]) {
        video.pause();

        var fps = 10;
        var intervalRewind = setInterval(function() {
          if (
            video.currentTime >= 0 &&
            video.currentTime < states[currentIndex].videoTime
          ) {
            clearInterval(intervalRewind);
            video.pause();
            activateScroll(true);
          } else {
            if (video.currentTime === 0) {
              clearInterval(intervalRewind);
            }
            video.currentTime += -(1 / fps);
          }
        }, states[currentIndex + 1].videoInterval);
      }
    }

    if (
      currentStateIndex <= states.length - 4 &&
      origin.index > states.length - 4
    ) {
      createFadeVideoTimeline(
        direction === DIRECTIONS.UP,
        destinationIndex,
        origin,
        true
      );
      video.currentTime = states[currentStateIndex].videoTime;
    } else if (currentStateIndex > 1) {
      if (currentStateIndex === states.length - 4) {
        createFadeVideoTimeline(
          direction === DIRECTIONS.UP,
          destinationIndex,
          origin,
          true
        );
      } else if (currentStateIndex > states.length - 4) {
        if (
          origin.index <= states.length - 4 &&
          direction === DIRECTIONS.DOWN
        ) {
          createFadeVideoTimeline(
            direction === DIRECTIONS.UP,
            destinationIndex,
            origin,
            false
          );
        }
      }
    }
  }

  window.onwheel = function(e) {
    if (clickMove === false) {
      if (Math.abs(e.deltaY) < 10) {
        allowScroll = false;
      } else {
        allowScroll = true;
      }
    }
  };

  window.ontouchmove = function(e) {
    allowScroll = true;
  };

  new fullpage("#pinContainer", {
    licenseKey: "CC8D3B9C-47F7455D-822D3532-0C1663CA",
    menu: ".navigation-list",
    scrollOverflow: true,
    lockAnchors: true,
    scrollingSpeed: 1800,
    touchSensitivity: 15,
    onLeave: function(origin, destination, direction) {
      if (
        (origin.index === 6 && direction === "down") ||
        (origin.index === 0 && direction === "up")
      ) {
        return false;
      }

      if (allowScroll) {
        if (
          (origin.index === 5 && destination.index === 6) ||
          (origin.index === 6 && destination.index === 5)
        ) {
          $(".section-gallery .footer, .section-cta .footer").removeClass(
            "animation-block3"
          );
          TweenMax.set($(".section-gallery .footer, .section-cta .footer"), {
            clearProps: "all"
          });
        } else {
          $(".section-gallery .footer, .section-cta .footer").addClass(
            "animation-block3"
          );
        }

        if (destination.index === 6) {
          $(".menu .menu-link").addClass("color-delay");
          setTimeout(() => {
            $(".menu .menu-link").removeClass("color-delay");
          }, 400);
        }

        if (direction === DIRECTIONS.DOWN) {
          if (origin.index === 0) {
            audio.pause();
            audio.currentTime = 0;
            audioProgress.restart();
            audioProgress.pause();
            $(".solo-sound").removeClass("play");
          }
          const timeLineComplete = createTimeline(
            timelineStates[destination.index - 1].endSection,
            true,
            null
          );
          const timeLineStart = createTimeline(
            timelineStates[origin.index].startSection,
            false,
            timeLineComplete
          );
          timeLineStart.play();
        } else {
          if (timelineStates[destination.index]) {
            const timeLineComplete = createTimeline(
              timelineStates[destination.index].startSection,
              true,
              null
            );
            const timeLineStart = createTimeline(
              timelineStates[origin.index - 1].endSection,
              false,
              timeLineComplete
            );
            timeLineStart.play();
          }
        }

        if (Math.abs(destination.index - origin.index) > 1) {
          clickMove = true;
        }
        if ($(window).width() > TABLET_BREAKPOINT_VALUE) {
          updateState(direction, destination.index, clickMove, origin);
        } else {
          playSequence(direction, destination.index, origin.index);
          createFadeGuitarShadowTimeline(
            direction,
            origin.index,
            destination.index
          );
        }
      } else {
        return false;
      }
    },
    afterLoad: function(origin, destination, direction) {
      clickMove = false;
      allowScroll = false;

      if (destination.index === 6) {
        document.querySelector(".cta-media").play();
      } else {
        document.querySelector(".cta-media").pause();
        document.querySelector(".cta-media").currentTime = 0;
      }
    },
    afterResize: function(width, height) {
      updateAnimationState();

      if ($(window).width() > TABLET_BREAKPOINT_VALUE) {
        updateDesktopAnimation = false;
        updateMobileAnimation = true;
      } else {
        updateDesktopAnimation = true;
        updateMobileAnimation = false;
      }
    }
  });
}
