const BREAKPOINT_VALUE = 768;
const MEDIUM_BREAKPOINT_VALUE = 992;
const TABLET_BREAKPOINT_VALUE = 1024;
const LARGE_SCREEN_BREAKPOINT_VALUE = 2000;
const windowWidth = $(window).width();
const SCREENWIDTH = windowWidth;

let isSafari;
let isFirefox;
let canvasVideo;
let isMobileView;

const ua = navigator.userAgent.toLowerCase();
if (ua.indexOf("safari") != -1 && !(ua.indexOf("chrome") > -1)) {
  isSafari = true;
} else {
  isSafari = false;
}

if (ua.indexOf("firefox") !== -1) {
  isFirefox = true;
} else {
  isFirefox = false;
}

if (windowWidth < BREAKPOINT_VALUE) {
  isMobileView = true;
} else {
  isMobileView = false;
}

$(document).ready(function() {
  if ($("#map").length) {
    setTimeout(function() {
      initMap();
    }, 300);
  }

  if ($(".main-page").length) {
    const video = document.querySelector("#guitar-video");

    if (windowWidth > TABLET_BREAKPOINT_VALUE) {
      var playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise.then(_ => {
          video.pause();
          video.currentTime = 0;
          canvasVideo = seeThru.create("#guitar-video", {
            start: "external"
          });
        });
      }
    } else {
      $(".guitar-video").addClass("hidden");
      $(".sequence-step-1").addClass("animate");
    }

    initFullpage();

    initGuitarsSlider();

    initTestimonialsSlider();

    if (windowWidth < BREAKPOINT_VALUE) {
      initGallerySlider();
    }
  }

  if ($(".article-page").length) {
    if (windowWidth < BREAKPOINT_VALUE) {
      initArticleSlider();
    }
  }

  //MOBILE MENU

  $(".mobile-menu-button").on("click", function() {
    $(".header").toggleClass("mobile-menu-open");
  });

  $("#language-menu").click(function() {
    $(this).toggleClass("language-menu-open");
  });

  $(".product-item").on("click", function() {
    if (isMobileView) {
      $(this).toggleClass("open");
    }
  });

  //LANGUAGE SWITCHER

  (function() {
    let $lang;

    $(".language-item").on("click", function() {
      $lang = $(this).text();
      $(".language-item.selected").removeClass("selected");
      $(this).addClass("selected");
      $(".current-language").text($lang);
    });
  })();

  // NAVIGATION

  $(".navigation-link").on("click", function(event) {
    event.preventDefault();
    return;
  });
});

window.onresize = function(event) {
  if ($(window).width() !== SCREENWIDTH) {
    if (window.innerWidth < BREAKPOINT_VALUE) {
      isMobileView = true;
    } else {
      isMobileView = false;
    }
    if ($("body").hasClass("main-page")) {
      if (window.innerWidth >= BREAKPOINT_VALUE) {
        if (gallerySlider) {
          gallerySlider.destroy(false, true);
        }
      } else {
        initGallerySlider();
        initArticleSlider();
      }

      if (window.innerWidth <= TABLET_BREAKPOINT_VALUE) {
        $(".guitar-video").addClass("hidden");
        if (canvasVideo) {
          canvasVideo.revert();
          canvasVideo = null;
        }
      } else {
        $(".sequence-step.animate").removeClass("animate");
        if (!canvasVideo) {
          canvasVideo = seeThru.create("#guitar-video", {
            start: "external"
          });
        }
      }

      if (window.innerWidth >= MEDIUM_BREAKPOINT_VALUE) {
        if (calloutsSlider) {
          calloutsSlider.destroy(false, true);
        }
      } else {
        setTimeout(function() {
          initCalloutsSlider();
          calloutsSlider.autoplay.start();
        }, 100);
      }
    }

    if ($("body").hasClass("article-page")) {
      if (window.innerWidth >= BREAKPOINT_VALUE) {
        if (articleSlider) {
          articleSlider.destroy(false, true);
        }
      } else {
        initArticleSlider();
      }
    }
  }
};
