(function() {
  const windowWidth = $(window).width();
  const TABLET_BREAKPOINT_VALUE = 1024;
  const MEDIUM_BREAKPOINT_VALUE = 992;
  let MOBILE_IMAGES_ARRAY = [
    "./application/images/sequence/start-guitar.png",
    "./application/images/sequence/mobile-sequence-2-min.png",
    "./application/images/sequence/mobile-sequence-3-min.png",
    "./application/images/sequence/mobile-sequence-4-min.png"
  ];

  let calcPercent, guitarContainer;

  let imagesArray = [];

  if (windowWidth > TABLET_BREAKPOINT_VALUE) {
    $(".preload-image").each(function(index, item) {
      imagesArray.push($(item).attr("src"));
    });
    guitarContainer = ".guitar-video";
  } else {
    imagesArray = MOBILE_IMAGES_ARRAY;
    guitarContainer = ".sequence-layer";
  }

  const progress = $(".loader-progress");
  const progressCircle = progress.find("circle");
  const percent = $(".loader-progress-percentage");

  const circleProgressLength = Math.ceil(
    progressCircle.attr("stroke-dashoffset")
  );

  function preload(imagesArray) {
    let increment = Math.floor(circleProgressLength / imagesArray.length);

    new TimelineMax().fromTo(
      ".loader-start-text",
      0.8,
      {
        y: "100%"
      },
      {
        y: "0%",
        onStart: function() {
          $(".loader-content").removeClass("hidden");
          $(".loader-start-text").removeClass("start");
        },
        onComplete: function() {
          TweenMax.set($(this.target), { clearProps: "all" });
          $(".loader-progressbar").removeClass("hidden");

          new TimelineMax().fromTo(
            ".loader-footer",
            0.5,
            {
              opacity: 0
            },
            {
              opacity: 1,
              onStart: function() {
                $(".loader-footer").removeClass("hidden");
              },
              onComplete: function() {
                TweenMax.set($(this.target), { clearProps: "all" });
              }
            }
          );

          $(imagesArray).each(function(index, item) {
            $("<img>")
              .attr("src", item)
              .on("load", function() {
                progressCircle.animate(
                  {
                    strokeDashoffset: "-=" + increment
                  },
                  {
                    step: function(number, tween) {
                      percent.text(
                        Math.floor(100 - (number / circleProgressLength) * 100)
                      );
                      if (
                        number <
                        circleProgressLength - increment * imagesArray.length
                      ) {
                        setTimeout(() => {
                          progressCircle.css("strokeDashoffset", "0px");
                        }, 0);

                        progressCircle.stop(true, false);
                        percent.text("100");
                        $(".loader-content").addClass("finish");

                        percent.text("100");
                        clearInterval(calcPercent);
                        const appearaceAnimation = startAppearance();

                        new TimelineMax()
                          .fromTo(
                            ".loader-progressbar, .loader-footer",
                            0.5,
                            {
                              opacity: 1
                            },
                            {
                              opacity: 0
                            },
                            0.2
                          )
                          .fromTo(
                            ".loader",
                            0.8,
                            {
                              height: "100%"
                            },
                            {
                              height: $(".header").height(),
                              ease: CustomEase.create(
                                "custom",
                                "M0,0 C0.266,0.412 0.256,0.458 0.44,0.644 0.622,0.828 0.766,0.95 1,1"
                              ),
                              onStart: function() {
                                fullpage_api.setAllowScrolling(false);
                                fullpage_api.setKeyboardScrolling(false);
                                appearaceAnimation.play();
                                $(".loader-content").addClass("hidden");
                              },
                              onComplete: function() {
                                $("body").addClass("loaded");
                                $(".header").css("z-index", "20");
                                $(".lines-grid").css("z-index", "-1");
                                $(".loader").addClass("hidden");
                              }
                            },
                            0.8
                          );
                      } else {
                        if (number <= circleProgressLength / 2) {
                          $(".loader-start-text").addClass("hidden");
                          $(".loader-finish-text").removeClass("hidden");
                        }
                      }
                    }
                  },
                  100
                );
              });
          });
        }
      }
    );
  }

  function startAppearance() {
    return new TimelineMax({ paused: true })
      .fromTo(
        ".fog-wrapper",
        1,
        {
          opacity: 0
        },
        {
          opacity: 1,
          onComplete: function() {
            TweenMax.set($(".fog-wrapper"), { clearProps: "all" });
          }
        },
        0.1
      )
      .fromTo(
        ".stars-wrapper",
        1,
        {
          opacity: 0
        },
        {
          opacity: 0.4,
          y: 0,
          onComplete: function() {
            TweenMax.set($(".stars-wrapper"), { clearProps: "all" });
          }
        },
        0.3
      )
      .fromTo(
        ".header",
        0.8,
        {
          opacity: 0
        },
        {
          opacity: 1,
          onStart: function() {
            $(".header").css("z-index", "20");
          },
          onComplete: function() {
            TweenMax.set($(".header"), { clearProps: "all" });
          }
        },
        0.5
      )
      .fromTo(
        ".section-overview .section-title",
        0.8,
        {
          opacity: 0,
          y: -10
        },
        {
          opacity: 1,
          y: 0,
          onComplete: function() {
            TweenMax.set($(".section-overview .section-title"), {
              clearProps: "all"
            });
          }
        },
        0.5
      )
      .fromTo(
        ".navigation-box",
        0.5,
        {
          opacity: 0
        },
        {
          opacity: 1
        },
        0.5
      )
      .fromTo(
        ".section-overview .section-description",
        0.8,
        {
          opacity: 0,
          y: -10
        },
        {
          opacity: 1,
          y: 0,
          onComplete: function() {
            TweenMax.set($(".section-overview .section-description"), {
              clearProps: "all"
            });
          }
        },
        0.6
      )
      .fromTo(
        ".section-overview .button, .section-overview .callouts",
        0.8,
        {
          opacity: 0,
          y: -10
        },
        {
          opacity: 1,
          y: 0,
          onComplete: function() {
            TweenMax.set(
              $(".section-overview .button, .section-overview .callouts"),
              { clearProps: "all" }
            );
          }
        },
        0.7
      )
      .fromTo(
        guitarContainer,
        1.5,
        {
          opacity: 0
        },
        {
          opacity: 1,
          ease: Power1.easeOut,
          onComplete: function() {}
        },
        0.8
      )
      .fromTo(
        guitarContainer,
        2,
        {
          scale: 0.9
        },
        {
          scale: 1,
          ease: Power1.easeOut,
          onComplete: function() {
            TweenMax.set($(".guitar-video"), { clearProps: "all" });
            TweenMax.set($(guitarContainer), { clearProps: "all" });
            fullpage_api.setAllowScrolling(true);
            fullpage_api.setKeyboardScrolling(true);
          }
        },
        0.8
      )
      .fromTo(
        ".guitar-shadow",
        1.5,
        {
          opacity: 0,
          scale: 0.85
        },
        {
          opacity: 1,
          scale: 1,
          onComplete: function() {
            TweenMax.set($(".guitar-shadow"), { clearProps: "all" });
          }
        },
        0.9
      )
      .fromTo(
        ".solo-sound",
        0.5,
        {
          opacity: 0,
          scale: 0.8
        },
        {
          opacity: 1,
          scale: 1,
          onStart: function() {
            $(".solo-sound").removeClass("hidden");
          }
        },
        1.1
      )
      .fromTo(
        ".lines-grid",
        0.5,
        {
          opacity: 0
        },
        {
          opacity: 1
        },
        1.2
      );
  }
  if ($("body").hasClass("main-page")) {
    preload(MOBILE_IMAGES_ARRAY);
  }

  $(window).on("load", function() {
    if ($("body").hasClass("main-page")) {
      if (windowWidth < MEDIUM_BREAKPOINT_VALUE) {
        initCalloutsSlider();
        calloutsSlider.autoplay.start();
      }

      if (isFirefox) {
        $(".audio-container").css(
          "width",
          $(".audio-container .sequence-image-hidden").width() + "px"
        );
      }
    } else {
      $("body").addClass("loaded");
    }

    (function() {
      const $asyncImages = $(".async-image");
      let imageSrc;
      $asyncImages.each(function(index, item) {
        imageSrc = $(item).data("mobile-src");

        $("<img>")
          .attr("src", imageSrc)
          .on("load", function() {
            $(item).addClass("loaded");

            if ($(".sequence-step-1").hasClass("loaded")) {
              $(".guitar-start-step").addClass("loaded");
            }
          });
      });
    })();
  });
})();
