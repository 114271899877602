function timelineStates() {
  const timelineStates = [
    {
      name: "Design",
      startSection: ".section-overview",
      endSection: ".section-design"
    },
    {
      name: "Sound",
      startSection: ".section-design",
      endSection: ".section-sound"
    },
    {
      name: "Details",
      startSection: ".section-sound",
      endSection: ".section-details"
    },
    {
      name: "Colors",
      startSection: ".section-details",
      endSection: ".section-colors"
    },
    {
      name: "Gallery",
      startSection: ".section-colors",
      endSection: ".section-gallery"
    },
    {
      name: "CTA",
      startSection: ".section-gallery",
      endSection: ".section-cta"
    }
  ];

  return timelineStates;
}