// GUITARS DESKTOP SLIDER

function initGuitarsSlider() {
  new Swiper('#guitars-slider-desktop', {
    slidesPerView: 1,
    grabCursor: true,
    autoplay: {
      delay: 5000,
    },
    loop: true,
    fadeEffect: {
      crossFade: true
    },
    effect: 'fade',
    on: {
      resize: function () {
        this.update();
      },
    },
    pagination: {
      clickable: true,
      el: '.guitars-slider-pagination',
    },
    navigation: {
      nextEl: '#guitars-slider-desktop .guitars-slider-btn-next',
      prevEl: '#guitars-slider-desktop .guitars-slider-btn-prev',
    }
  });
};

//GALLERY MOBILE SLIDER

let gallerySlider;

function initGallerySlider() {
  gallerySlider = new Swiper('.gallery-slider', {
    slidesPerView: 'auto',
    on: {
      resize: function () {
        this.update();
      },
    },
    breakpoints: {
      767: {
        spaceBetween: 20
      }
    },
    pagination: {
      el: '.gallery-slider-pagination',
      type: 'progressbar'
    }
  });
}

//CALLOUTS SLIDER

let calloutsSlider;

function initCalloutsSlider() {
  calloutsSlider = new Swiper('.callouts-slider', {
    speed: 7000,
    spaceBetween: 60,
    loop: true,
    loopedSlides: 4,
    disableOnInteraction: false,
    waitForTransition: false,
    autoplay:{
      delay: 1,
    },
    slidesPerView: 'auto',
    on: {
      init: function() {
        this.autoplay.start();
      },
      resize: function () {
        if(window.innerWidth >= 992) {
          this.destroy(false, true);
        }
        this.update();
      },
    },
    breakpoints: {
      768: {
        speed: 7000,
        spaceBetween: 30,
        loop: true,
        disableOnInteraction: false,
        waitForTransition: false,
        loopedSlides: 4,
        autoplay:{
          delay: 1,
        },
        slidesPerView: 'auto'
      },
      575: {
        speed: 7000,
        spaceBetween: 50,
        loop: true,
        disableOnInteraction: false,
        waitForTransition: false,
        loopedSlides: 4,
        autoplay:{
          delay: 1,
        },
        slidesPerView: "auto"
      }
    }
  });
}

//TESTIMONIALS MOBILE SLIDER

function initTestimonialsSlider() {
  new Swiper('.testimonials-slider', {
    slidesPerView: 1,
    spaceBetween: 20,
    grabCursor: true,
    on: {
      resize: function () {
        this.update();
      },
    },
    pagination: {
      clickable: true,
      el: '.testimonials-slider-pagination',
    },
    navigation: {
      nextEl: '.testimonials-slider-btn-next',
      prevEl: '.testimonials-slider-btn-prev',
    }
  });
}

//ARTICLE SLIDER

let articleSlider;

function initArticleSlider() {
  articleSlider = new Swiper('.other-posts-slider', {
    slidesPerView: 2,
    grabCursor: true,
    spaceBetween: 0,
    on: {
      resize: function () {
        if(window.innerWidth >= 768) {
          this.destroy(false, true);
        }
        
        this.update();
      }
    },
    breakpoints: {
      575: {
        slidesPerView: 1,
        spaceBetween: 20
      }
    },
    pagination: {
      el: '.other-posts-slider-pagination',
      clickable: true
    }
  });
}