//AUDIO

if ($("body").hasClass("main-page")) {
  let soloAudio = new Audio($(".solo-sound").data("audio-src"));

  let path = document.querySelector('.circle-progress');
  const length = Math.ceil($(path).find("circle").attr("stroke-dashoffset"));

  let audioDuration;
  let audioProgress;

  const audioIframe = document.querySelector(".audio-iframe");

  let audio;
  audio = document.querySelector(".solo-sound audio");

  function animateAudioProgress(duration) {
    return new TimelineMax({ paused: true })
      .fromTo(
        ".circle-progress circle",
        duration,
        { strokeDashoffset: "0" },
        {
          strokeDashoffset: length,
          ease: Power0.easeNone,
        }
      )
  }

  soloAudio.onloadedmetadata = function () {
    audioDuration = soloAudio.duration;
    audioProgress = animateAudioProgress(audioDuration);
  };

  function initAudio() {
    $(".solo-sound").on("click", function () {
      audio = this.querySelector("audio");

      $(this).toggleClass("play");
      if ($(this).hasClass("play")) {
        audio.play();
        audio.addEventListener("timeupdate", function () {
          if (audio.currentTime >= audioDuration) {
            audio.pause();
            audio.currentTime = 0;
            $(".solo-sound").removeClass("play");
            audioProgress.restart();
            audioProgress.pause();
          }
        });

        audioProgress.play();
      } else {
        if (audio) {
          audio.pause();
          audioProgress.pause();
        }
      }
    });

    //Check for Safari to implement audio playback on click instead of mouseenter

    if (isSafari) {
      $(".chord-sound").on("click", function () {
        audio = new Audio($(this).data("audio-src"));
        audio.play();

      });

      $(".chord-sound").on("mouseleave", function () {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
        }
      });
    } else {
      $(".chord-sound").on("mouseenter", function (event) {
        var iframe = document.createElement("iframe");
        document.body.appendChild(iframe);
        iframe.id = 'iframe';
        iframe.style.width = "50px";
        iframe.style.height = "50px";
        iframe.src = $(this).data("audio-src");
      });

      $(".chord-sound").on("mouseleave", function () {
        document.getElementById("iframe").remove();
      });
    }

    function playSounds(e) {
      if (!$(".section-details").hasClass("active")) {
        return;
      }
      switch (e.keyCode) {

        case 65: // Key A
          audioIframe.setAttribute('src', $(".am-sound").data("audio-src"));
          $(".am-sound").addClass("active").delay(300).queue(function () {
            $(this).removeClass("active").dequeue();
          });
          break;
        case 67:   // Key C
          audioIframe.setAttribute('src', $(".c-sound").data("audio-src"));
          $(".c-sound").addClass("active").delay(300).queue(function () {
            $(this).removeClass("active").dequeue();
          });
          break;
        case 70:   // Key F
          audioIframe.setAttribute('src', $(".f-sound").data("audio-src"));
          $(".f-sound").addClass("active").delay(300).queue(function () {
            $(this).removeClass("active").dequeue();
          });
          break;
        case 71:   // key G
          audioIframe.setAttribute('src', $(".g-sound").data("audio-src"));
          $(".g-sound").addClass("active").delay(300).queue(function () {
            $(this).removeClass("active").dequeue();
          });
          break;
      }
    }
    addEventListener("keydown", playSounds);
  }
}