function animationStates() {
  const states = [
    {
      name: "Overview",
      container: ".sequence-step-1",
      animationSteps: 17,
      animationDuration: "1.2",
      videoTime: 0,
      circleOpacity: 1,
      audioVisible: false,
      soloAudioVisible: true,
      audioDelay: 0,
      shadowDelay: 1.5
    },
    {
      name: "Design",
      container: ".sequence-step-2",
      animationSteps: 11,
      animationDuration: "1",
      videoTime: 2.5,
      videoInterval: 62,
      audioVisible: false,
      soloAudioVisible: false,
      audioDelay: 0,
      shadowDelay: 0.8
    },
    {
      name: "Sound",
      container: ".sequence-step-3",
      animationSteps: 17,
      animationDuration: "1.2",
      videoTime: 4.5,
      videoInterval: 60,
      audioVisible: false,
      soloAudioVisible: false,
      audioDelay: 0,
      shadowDelay: 1
    },
    {
      name: "Details",
      container: ".sequence-step-4",
      animationDuration: "1",
      videoTime: 7.3,
      videoInterval: 78,
      audioVisible: true,
      soloAudioVisible: false,
      audioDelay: 0,
      shadowDelay: 1.0
    },
    {
      name: "Colors",
      audioVisible: false,
      soloAudioVisible: false,
      audioDelay: 1,
      shadowDelay: 0.8
    },
    {
      name: "Gallery",
      audioVisible: false,
      soloAudioVisible: false,
      audioDelay: 1,
      shadowDelay: 0.8
    },
    {
      name: "CTA",
      audioVisible: false,
      soloAudioVisible: false,
      audioDelay: 1,
      shadowDelay: 0.8
    }
  ];

  return states;
}
